<template>
  <div>
    <div class="bpr-fplayers bpr-fplayers-movil">
      <div class="bpr-fplayers__header">
        <div class="line-separator"></div>
        <div class="bpr-fplayers__header__title">
          Jugadores Destacados
        </div>
      </div>

      <div class="bpr-fplayers__filterTime">
        <div class="center-item"><RadioGamePeriod :section="'jugadores destacados'" /></div>
      </div>
      <div class="bpr-fplayers__filterPlay">
        <div class="center-item"><RadioPlayingAs :is-mobile="isMobile" :section="'jugadores destacados'" /></div>
      </div>
      <div class="bpr-fplayers__table">
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__header">
            <div class="bpr-fplayers__itemheadertable float-item-left">
              <div class="bpr-fplayers__itemheadertable__shield ">
                <img :src="selectedGame.homeTeam.logo" width="50" />
              </div>
              <div class="bpr-fplayers__itemheadertable__name margin-item-left textaling-item-left">
                {{ selectedGame.homeTeam.colloquial_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-fplayers__table__categories"></div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__header">
            <div class="bpr-fplayers__itemheadertable float-item-right">
              <div class="bpr-fplayers__itemheadertable__name margin-item-right textaling-item-right">
                {{ selectedGame.awayTeam.colloquial_name }}
              </div>
              <div class="bpr-fplayers__itemheadertable__shield">
                <img :src="selectedGame.awayTeam.logo" width="50" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bpr-fplayers__table no-margin"
        v-if="getPlayersFeatureGoals.home.length > 0 || getPlayersFeatureGoals.away.length > 0"
      >
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__catcontainer">
            <template v-for="(player, i) in getPlayersFeatureGoals.home">
              <div :key="i + 'homeG'" class="bpr-fplayers__itemplayer float-item-left">
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-left margin-item-left20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__stats textaling-item-right">{{ player.total }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="bpr-fplayers__table__categories">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Goleador</div>
          </div>
        </div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__catcontainer">
            <template v-for="(player, i) in getPlayersFeatureGoals.away">
              <!-- player -->
              <div :key="i + 'homeA'" class="bpr-fplayers__itemplayer float-item-right">
                <div class="bpr-fplayers__itemplayer__stats textaling-item-left">{{ player.total }}</div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-right margin-item-right20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
      </div>
      <div
        class="bpr-fplayers__containersep"
        v-if="getPlayersFeatureGoals.home.length > 0 || getPlayersFeatureGoals.away.length > 0"
      >
        <div class="line-separator"></div>
      </div>
      <div
        class="bpr-fplayers__table no-margin"
        v-if="getPlayersFeaturedShots.home.length > 0 || getPlayersFeaturedShots.away.length > 0"
      >
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedShots.home">
              <!-- player -->
              <div :key="i + 'homeSH'" class="bpr-fplayers__itemplayer float-item-left">
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-left margin-item-left20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__stats textaling-item-right">{{ player.total }}</div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
        <div class="bpr-fplayers__table__categories">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Tiros a Gol</div>
          </div>
        </div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedShots.away">
              <!-- player -->
              <div :key="i + 'homeSA'" class="bpr-fplayers__itemplayer float-item-right">
                <div class="bpr-fplayers__itemplayer__stats textaling-item-left">{{ player.total }}</div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-right margin-item-right20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
      </div>
      <div
        class="bpr-fplayers__containersep"
        v-if="getPlayersFeaturedShots.home.length > 0 || getPlayersFeaturedShots.away.length > 0"
      >
        <div class="line-separator"></div>
      </div>
      <div
        class="bpr-fplayers__table no-margin"
        v-if="getPlayersFeaturedCenters.home.length > 0 || getPlayersFeaturedCenters.away.length > 0"
      >
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedCenters.home">
              <!-- player -->
              <div :key="i + 'homeCH'" class="bpr-fplayers__itemplayer float-item-left">
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-left margin-item-left20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__stats textaling-item-right">{{ player.total }}</div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
        <div class="bpr-fplayers__table__categories">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Centros</div>
          </div>
        </div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedCenters.away">
              <!-- player -->
              <div :key="i + 'homeCA'" class="bpr-fplayers__itemplayer float-item-right">
                <div class="bpr-fplayers__itemplayer__stats textaling-item-left">{{ player.total }}</div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-right margin-item-right20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
      </div>
      <div
        class="bpr-fplayers__containersep"
        v-if="getPlayersFeaturedCenters.home.length > 0 || getPlayersFeaturedCenters.away.length > 0"
      >
        <div class="line-separator"></div>
      </div>
      <div
        class="bpr-fplayers__table no-margin"
        v-if="getPlayersFeaturedPass.home.length > 0 || getPlayersFeaturedPass.away.length > 0"
      >
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedPass.home">
              <!-- player -->
              <div :key="i + 'homePH'" class="bpr-fplayers__itemplayer float-item-left">
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-left margin-item-left20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__stats textaling-item-right">{{ player.total }}</div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
        <div class="bpr-fplayers__table__categories">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Pases</div>
          </div>
        </div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__catcontainer">
            <template v-for="(player, i) in getPlayersFeaturedPass.away">
              <!-- player -->
              <div :key="i + 'homePA'" class="bpr-fplayers__itemplayer float-item-right">
                <div class="bpr-fplayers__itemplayer__stats textaling-item-left">{{ player.total }}</div>
                <div class="bpr-fplayers__itemplayer__name textaling-item-right margin-item-right20x">
                  {{ player.name }}
                </div>
                <div class="bpr-fplayers__itemplayer__img ">
                  <img :src="player.img" class="borderRadius" />
                </div>
              </div>
              <!-- player -->
            </template>
          </div>
        </div>
      </div>
      <div
        class="bpr-fplayers__containersep"
        v-if="getPlayersFeaturedPass.home.length > 0 || getPlayersFeaturedPass.away.length > 0"
      >
        <div class="line-separator"></div>
      </div>
      <div class="leyend-last-season" v-if="getFeaturedPlayersPastSeason">
        Estadísticas de torneo CL2023.
      </div>
    </div>
    <!-- MOVIL MOVIL MOVILE MOVILE -->
    <div class="bpr-fplayers bpr-fplayers-pc">
      <div class="bpr-fplayers__header">
        <div class="line-separator"></div>
        <div class="bpr-fplayers__header__title">
          Jugadores Destacados
        </div>
      </div>
      <div class="bpr-fplayers__filterTime">
        <div class="center-item"><RadioGamePeriod :section="'jugadores destacados'" /></div>
      </div>
      <div class="bpr-fplayers__filterPlay">
        <div class="center-item"><RadioPlayingAs :is-mobile="isMobile" :section="'jugadores destacados'" /></div>
      </div>
      <div class="bpr-fplayers__table first-table-movil">
        <div class="bpr-fplayers__table__home">
          <div class="bpr-fplayers__table__home__header">
            <div class="bpr-fplayers__itemheadertable float-item-left">
              <div class="bpr-fplayers__itemheadertable__shield ">
                <img :src="selectedGame.homeTeam.logo" width="50" />
              </div>
              <div class="bpr-fplayers__itemheadertable__name margin-item-left textaling-item-left">
                {{ selectedGame.homeTeam.colloquial_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="bpr-fplayers__table__away">
          <div class="bpr-fplayers__table__away__header">
            <div class="bpr-fplayers__itemheadertable float-item-right">
              <div class="bpr-fplayers__itemheadertable__name margin-item-right textaling-item-right">
                {{ selectedGame.awayTeam.colloquial_name }}
              </div>
              <div class="bpr-fplayers__itemheadertable__shield">
                <img :src="selectedGame.awayTeam.logo" width="50" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpr-fplayers__filterPlay"></div>
      <!-- item -->
      <div class="bpr-fplayers__table-movil " v-if="getPlayersFeatureGoals.movil.length > 0">
        <div class="bpr-fplayers__table-movil__rowcategory">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Goleador</div>
          </div>
        </div>
        <div class="bpr-fplayers__table-movil__containerstats">
          <template v-for="(player, i) in getPlayersFeatureGoals.movil">
            <div :key="i + 'a'" class="bpr-fplayers__table-movil__containerstats__itemplayer first-itemplayer">
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__team">
                <img :src="player.team_img" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__player">
                <img :src="player.img" class="borderRadius" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__name">{{ player.name }}</div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__stats">{{ player.total }}</div>
            </div>
            <div :key="i + 'b'" v-if="i != getPlayersFeatureGoals.movil.length - 1" class="bpr-fplayers__containersep">
              <div class="line-separator"></div>
            </div>
          </template>
        </div>
      </div>
      <!-- item -->
      <!-- item -->
      <div class="bpr-fplayers__table-movil " v-if="getPlayersFeaturedShots.movil.length > 0">
        <div class="bpr-fplayers__table-movil__rowcategory">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Tiros a Gol</div>
          </div>
        </div>
        <div class="bpr-fplayers__table-movil__containerstats">
          <template v-for="(player, i) in getPlayersFeaturedShots.movil">
            <div :key="i + 'c'" class="bpr-fplayers__table-movil__containerstats__itemplayer first-itemplayer">
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__team">
                <img :src="player.team_img" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__player">
                <img :src="player.img" class="borderRadius" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__name">{{ player.name }}</div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__stats">{{ player.total }}</div>
            </div>
            <div :key="i + 'd'" v-if="i != getPlayersFeaturedShots.movil.length - 1" class="bpr-fplayers__containersep">
              <div class="line-separator"></div>
            </div>
          </template>
        </div>
      </div>
      <!-- item -->
      <!-- item -->
      <div class="bpr-fplayers__table-movil " v-if="getPlayersFeaturedCenters.movil.length > 0">
        <div class="bpr-fplayers__table-movil__rowcategory">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Centros</div>
          </div>
        </div>
        <div class="bpr-fplayers__table-movil__containerstats">
          <template v-for="(player, i) in getPlayersFeaturedCenters.movil">
            <div :key="i + 'z'" class="bpr-fplayers__table-movil__containerstats__itemplayer first-itemplayer">
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__team">
                <img :src="player.team_img" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__player">
                <img :src="player.img" class="borderRadius" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__name">{{ player.name }}</div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__stats">{{ player.total }}</div>
            </div>
            <div
              :key="i + 'y'"
              v-if="i != getPlayersFeaturedCenters.movil.length - 1"
              class="bpr-fplayers__containersep"
            >
              <div class="line-separator"></div>
            </div>
          </template>
        </div>
      </div>
      <!-- item -->
      <!-- item -->
      <div class="bpr-fplayers__table-movil " v-if="getPlayersFeaturedPass.movil.length > 0">
        <div class="bpr-fplayers__table-movil__rowcategory">
          <div class="bpr-fplayers__itemcategory">
            <div class="bpr-fplayers__itemcategory__category">Pases</div>
          </div>
        </div>
        <div class="bpr-fplayers__table-movil__containerstats">
          <template v-for="(player, i) in getPlayersFeaturedPass.movil">
            <div :key="i + 'w'" class="bpr-fplayers__table-movil__containerstats__itemplayer first-itemplayer">
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__team">
                <img :src="player.team_img" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__player">
                <img :src="player.img" class="borderRadius" />
              </div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__name">{{ player.name }}</div>
              <div class="bpr-fplayers__table-movil__containerstats__itemplayer__stats">{{ player.total }}</div>
            </div>
            <div :key="i + 'x'" v-if="i != getPlayersFeaturedPass.movil.length - 1" class="bpr-fplayers__containersep">
              <div class="line-separator"></div>
            </div>
          </template>
        </div>
      </div>
      <!-- item -->
      <div class="leyend-last-season" v-if="getFeaturedPlayersPastSeason">
        Estadísticas de torneo CL2023.
      </div>
    </div>

    <!-- MOVIL MOVIL MOVILE MOVILE -->
  </div>
</template>

<script>
const featuredPlayersCategories = [1, 2, 6, 10];
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'BetFeaturedPlayers',
  components: {
    RadioGamePeriod: () => import('@/components/Sections/GolTipster-v2/General/RadioGamePeriod'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'filterPlayingAs', 'filterGamePeriod']),
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', [
      'getFeaturedPlayersPastSeason',
      'getPlayersFeatureGoals',
      'getPlayersFeaturedShots',
      'getPlayersFeaturedCenters',
      'getPlayersFeaturedPass',
    ]),
    isMobile() {
      return this.displayWidth < 464;
    },
  },
  watch: {
    selectedGame: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fetchFeaturedPlayers(newValue);
        }
      },
    },
    filterPlayingAs() {
      this.setFeaturedPlayersData();
    },
    filterGamePeriod() {
      this.setFeaturedPlayersData();
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getFeaturedPlayersPerGame', 'setPlayersTops']),
    async fetchFeaturedPlayers(selectedGame) {
      try {
        if (selectedGame) {
          const promises = [];
          featuredPlayersCategories.forEach(categoryId => {
            promises.push(
              this.getFeaturedPlayersPerGame({
                season_id: selectedGame.seasonId,
                category_id: categoryId,
                home_team: selectedGame.homeTeam.team_id,
                away_team: selectedGame.awayTeam.team_id,
              }),
            );
          });
          await Promise.all(promises);
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    setFeaturedPlayersData() {
      featuredPlayersCategories.forEach(categoryId => {
        this.setPlayersTops(categoryId);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.borderRadius {
  border-radius: 5px;
}
.leyend-last-season {
  width: auto;
  height: 30px;
  margin: 0 auto;
  margin-top: 21px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #6b6c6c;
  font-family: 'Roboto-Medium';
  &__color {
    font-family: 'Roboto-Black';
    color: #132839;
  }
  @media screen and (max-width: 563px) {
    display: inline-block;
  }
}
.center-item {
  margin: 0 auto;
  display: inline-block;
  @media screen and (max-width: 463px) {
    width: 100% !important;
  }
}
.bpr-fplayers-movil {
  @media screen and (max-width: 613px) {
    display: none;
  }
}
.bpr-fplayers-pc {
  display: none;
  @media screen and (max-width: 613px) {
    display: block;
  }
}
.bpr-fplayers {
  width: 98%;
  height: auto;
  margin: 0 auto;
  position: relative;
  /* vbloque*/
  &__table-movil {
    display: inline-block;
    width: 100%;
    height: auto;
    margin-top: 26px;
    &__rowcategory {
      width: 100%;
      height: 62px;
    }
    &__containerstats {
      width: 100%;
      height: auto;
      margin-top: 12px;
      border-radius: 16px;
      border: solid 2px #edf2fa;
      padding-bottom: 16px;
      &__itemplayer {
        width: 87%;
        height: 83px;
        margin: 0 auto;
        display: inline-flex;
        &__team {
          width: 48px;
          height: 48px;
          margin-top: 17px;
        }
        &__player {
          width: 48px;
          height: 62px;
          margin-top: 7px;
          margin-left: 12px;
        }
        &__name {
          width: 60%;
          height: 65px;
          line-height: 65px;
          margin-top: 7px;
          font-size: 20px;
          font-weight: 500;
          font-family: 'Roboto-Medium';
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.22px;
          text-align: left;
          margin-left: 12px;
          color: #132839;
          @media screen and (max-width: 363px) {
            font-size: 18px;
          }
          @media screen and (max-width: 313px) {
            font-size: 16px;
          }
        }
        &__stats {
          width: 18%;
          height: 65px;
          line-height: 65px;
          margin-top: 7px;
          font-family: 'Roboto-Black';
          font-size: 47px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          text-align: right !important;
          letter-spacing: -0.47px;
          color: #132839;
          @media screen and (max-width: 413px) {
            font-size: 35px;
          }
          @media screen and (max-width: 313px) {
            font-size: 26px;
          }
        }
      }
    }
  }
  /* vbloque */
  &__header {
    position: relative;
    width: 100%;
    height: 42px;
    margin: 0 auto;
    &__title {
      width: 283px;
      height: 42px;
      position: absolute;
      line-height: 42px;
      background-color: white;
      font-family: 'Roboto-Black';
      font-size: 26px;
      font-weight: 800;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.26px;
      text-align: center;
      color: #132839;
    }
  }
  /* vbloque */
  &__filterTime {
    width: 265px;
    height: auto;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 4px;
  }
  /* vbloque */
  &__filterPlay {
    width: 386px;
    height: auto;
    margin: 0 auto;
    margin-top: 16px;
    border-radius: 4px;
    text-align: center;
    @media screen and (max-width: 613px) {
      margin-top: 14px;
      width: 98%;
    }
  }

  /* vbloque */
  &__table {
    display: inline-flex;
    width: 100%;
    height: auto;
    margin-top: 26px;
    &__home {
      width: 42%;
      height: auto;
      @media screen and (max-width: 613px) {
        width: 50%;
      }
      &__header {
        width: 100%;
        height: 76px;
      }
      &__catcontainer {
        width: 100%;
        height: auto;
      }
    }
    &__away {
      width: 42%;
      height: auto;
      @media screen and (max-width: 613px) {
        width: 50%;
      }
      &__header {
        width: 100%;
        height: 76px;
      }
      &__catcontainer {
        width: 100%;
        height: auto;
      }
    }
    &__categories {
      width: 18%;
      height: auto;
    }
  }
  /* vbloque */
  &__itemheadertable {
    width: 99%;
    height: 76px;
    display: inline-flex;
    &__shield {
      width: 50px;
      height: 56px;
      margin-top: 10px;
      position: relative;
      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 3px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__name {
      width: 60%;
      height: 56px;
      line-height: 56px;
      margin-top: 10px;
      font-size: 26px;
      font-family: 'Roboto-Black';
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.26px;
      color: #132839;

      @media screen and (max-width: 413px) {
        font-size: 22px;
      }
      @media screen and (max-width: 363px) {
        font-size: 20px;
      }
      @media screen and (max-width: 313px) {
        font-size: 18px;
      }
    }
  }
  /* vbloque */
  &__itemplayer {
    width: 99%;
    height: 82px;
    margin-top: 10px;
    display: inline-flex;
    &__img {
      width: 48px;
      height: 62px;
      margin-top: 7px;
    }
    &__name {
      width: 60%;
      height: 65px;
      line-height: 65px;
      margin-top: 7px;
      font-size: 22px;
      font-weight: 500;
      font-family: 'Roboto-Medium';
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.22px;

      color: #132839;
      @media screen and (max-width: 663px) {
        font-size: 20px;
      }
      @media screen and (max-width: 613px) {
        font-size: 22px;
      }
      @media screen and (max-width: 813px) {
        width: 60%;
      }
    }
    &__stats {
      width: 24%;
      height: 65px;
      line-height: 65px;
      margin-top: 7px;
      font-family: 'Roboto-Black';
      font-size: 47px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      // text-align: center !important;
      letter-spacing: -0.47px;
      color: #132839;
      @media screen and (max-width: 813px) {
        font-size: 40px;
        width: 23%;
      }
      @media screen and (max-width: 763px) {
        font-size: 36px;
      }
      @media screen and (max-width: 713px) {
        font-size: 32px;
      }
    }
  }
  &__itemcategory {
    width: 100%;
    height: 82px;
    margin-top: 10px;
    @media screen and (max-width: 613px) {
      height: 62px;
      margin-top: 0px;
    }
    display: inline-flex;
    &__category {
      width: 91%;
      height: 42px;
      margin: 0 auto;
      margin-top: 15px !important;
      border-radius: 25px;
      background-color: #cbee6b;
      font-size: 22px;
      font-family: 'Roboto-Medium';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: -0.22px;
      text-align: center;
      color: #132839;
      @media screen and (max-width: 813px) {
        font-size: 20px;
        width: 87%;
      }
      @media screen and (max-width: 763px) {
        font-size: 18px;
        width: 85%;
      }
      @media screen and (max-width: 713px) {
        font-size: 17px;
        width: 82%;
        height: 36px;
        line-height: 36px;
        margin-top: 20px !important;
      }
      @media screen and (max-width: 613px) {
        width: 154px;
        height: 36px;
        margin-top: 13px !important;
      }
    }
  }
  /* vbloque*/
  &__containersep {
    width: 100%;
    height: 10px;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 613px) {
      width: 90%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
.first-itemplayer {
  margin-top: 16px;
}
.float-item-left {
  float: left;
  margin-left: 2px;
  align-items: left;
  justify-content: left;
}
.float-item-right {
  float: right;
  margin-right: 2px;
  align-items: right;
  justify-content: right;
}
.margin-item-left {
  margin-left: 10px;
}
.margin-item-right {
  margin-right: 10px;
}
.margin-item-left20x {
  margin-left: 12px;
}
.margin-item-right20x {
  margin-right: 12px;
}
.textaling-item-left {
  text-align: left;
}
.textaling-item-right {
  text-align: right;
}
.no-margin {
  margin-top: 0px !important;
}
.first-table-movil {
  margin-top: 40px;
}
.line-separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
}
</style>
